import { default as indexQW0XD9sGpiMeta } from "/codebuild/output/src444466684/src/src/pages/[companySlug]/faq/[id]/index.vue?macro=true";
import { default as indexMHMLOWjB5vMeta } from "/codebuild/output/src444466684/src/src/pages/[companySlug]/important-news/index.vue?macro=true";
import { default as indexWRL396unyTMeta } from "/codebuild/output/src444466684/src/src/pages/[companySlug]/index.vue?macro=true";
import { default as index1D4agx0TTDMeta } from "/codebuild/output/src444466684/src/src/pages/[companySlug]/news/[id]/index.vue?macro=true";
import { default as indexL7tgyy3rGzMeta } from "/codebuild/output/src444466684/src/src/pages/[companySlug]/news/index.vue?macro=true";
import { default as indexpR4oSWZdxgMeta } from "/codebuild/output/src444466684/src/src/pages/[companySlug]/preview/faq/index.vue?macro=true";
import { default as indexdMN6rQg9YaMeta } from "/codebuild/output/src444466684/src/src/pages/[companySlug]/preview/news/index.vue?macro=true";
export default [
  {
    name: "companySlug-faq-id",
    path: "/:companySlug()/faq/:id()",
    component: () => import("/codebuild/output/src444466684/src/src/pages/[companySlug]/faq/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-important-news",
    path: "/:companySlug()/important-news",
    component: () => import("/codebuild/output/src444466684/src/src/pages/[companySlug]/important-news/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug",
    path: "/:companySlug()",
    component: () => import("/codebuild/output/src444466684/src/src/pages/[companySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-news-id",
    path: "/:companySlug()/news/:id()",
    component: () => import("/codebuild/output/src444466684/src/src/pages/[companySlug]/news/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-news",
    path: "/:companySlug()/news",
    component: () => import("/codebuild/output/src444466684/src/src/pages/[companySlug]/news/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-preview-faq",
    path: "/:companySlug()/preview/faq",
    component: () => import("/codebuild/output/src444466684/src/src/pages/[companySlug]/preview/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-preview-news",
    path: "/:companySlug()/preview/news",
    component: () => import("/codebuild/output/src444466684/src/src/pages/[companySlug]/preview/news/index.vue").then(m => m.default || m)
  }
]